import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({opacity: 0}), animate('0.5s ease-in', style({ opacity: 1}))])
    ])
  ]
})
export class WelcomePageComponent implements OnInit {

  lang;
  direction;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.direction = this.changeDirDirection(this.lang);
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
      this.direction = this.changeDirDirection(this.lang);
    });
  }

  changeDirDirection(lang: string): string {
    if (lang === 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

}
