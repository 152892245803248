<div class="container-fluid page-container">
  <div class="row">
    <div class="col header-content"></div>
  </div>

  <div class="row justify-content-around" [dir]="direction">
    <div class="col-md-5 col-right" [ngClass]="{'txtltr': direction === 'ltr'}">
      <div class="row row1-height">
        <div class="col text-bold" >
          <!-- <p>בתקופה בה קהלים אינם יכולים, כבעבר, לצרוך תרבות באופן פיזי ולהגיע אל הגלריות ואל חללי התצוגה, אנו מוצאים יותר ויותר יוצרים אשר משתמשים בפלטפורמות אינטרנטיות להצגת עבודותיהם, כדוגמת רשתות חברתיות או דפי אינטרנט אישיים. בתערוכה המקוונת אוביקט מעבר, חללי התצוגה של בית האמנים ירושלים הופכים להדמיה במחשב. הסביבה הפיזית מתורגמת לחומר דיגיטלי, שנוצר מצילום, קוד ונקודות על מערכת צירים. קיום וירטואלי אינו דבר חדש, כיום הוא דבר שגור אשר ניבט אלינו מכל עבר. מה שמוסיף להשתנות הוא ההתפשטות של קיום זה, אשר כולל בתוכו עוד ועוד חוויות מוכרות. תערוכה זו רואה אור בזמן בו גלריות וירטואליות קמות והופכות לחלק ממקום הצגתה המקובל של אמנות. ועדיין - חוויית הצפייה באמנות בגלריה וירטואלית ולא גשמית נראית חריגה, זרה, ואולי חוטאת לעיקר - המפגש בין האדם לבין האוביקט הפיזי.</p> -->
          <p>{{ 'ABOUT.Paragraph-1' | translate}}</p>
        </div>
      </div>
      <div class="row row2-height">
        <div class="col title-content text-center" [dir]="direction">
          <!-- <h1>אוביקט מעבר</h1> -->
          <h1 [ngClass]="{'txtltr': direction === 'ltr'}">{{ 'ABOUT.Title' | translate }}</h1>
          <div class="row">
            <!-- <div class="col-lg" [dir]="direction">
              תערוכה מקוונת בבית האמנים ירושלים
            </div> -->
            <div class="col-lg" [dir]="direction" [ngClass]="{'txtltr': direction === 'ltr'}">
              {{ 'ABOUT.Subtitle' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row col-center">

      </div>
      <div class="row">
        <div class="col" [dir]="direction">
          <p>{{ 'ABOUT.Paragraph-2' | translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col" [dir]="direction">
          <p>{{ 'ABOUT.Paragraph-3' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-left">
      <div class="row icon-container">
        <img src="assets/SVG/ah-yellow.svg" alt="">
      </div>
      <div class="row" style="margin-top: 2.2rem;">
        <div class="col" [dir]="direction">
          <!-- <p>בתקופה בה קהלים אינם יכולים, כבעבר, לצרוך תרבות באופן פיזי ולהגיע אל הגלריות ואל חללי התצוגה, אנו מוצאים יותר ויותר יוצרים אשר משתמשים בפלטפורמות אינטרנטיות להצגת עבודותיהם, כדוגמת רשתות חברתיות או דפי אינטרנט אישיים. בתערוכה המקוונת אוביקט מעבר, חללי התצוגה של בית האמנים ירושלים הופכים להדמיה במחשב. הסביבה הפיזית מתורגמת לחומר דיגיטלי, שנוצר מצילום, קוד ונקודות על מערכת צירים. קיום וירטואלי אינו דבר חדש, כיום הוא דבר שגור אשר ניבט אלינו מכל עבר. מה שמוסיף להשתנות הוא ההתפשטות של קיום זה, אשר כולל בתוכו עוד ועוד חוויות מוכרות. תערוכה זו רואה אור בזמן בו גלריות וירטואליות קמות והופכות לחלק ממקום הצגתה המקובל של אמנות. ועדיין - חוויית הצפייה באמנות בגלריה וירטואלית ולא גשמית נראית חריגה, זרה, ואולי חוטאת לעיקר - המפגש בין האדם לבין האוביקט הפיזי.</p> -->
          <p>{{ 'ABOUT.Paragraph-4' | translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col" [dir]="direction">
          <!-- <p>ייחודה של תערוכה זו בכך שהיא מתקיימת בחלל וירטואלי שנוצר במיוחד עבורה. החלל הוא סריקה תלת-ממדית של מבנה בית האמנים בירושלים, החיה בסביבה ממוחשבת מתאימה. העבודות המוצגות בה עשויות מחומר דיגיטלי והתנועה ביניהן מתאפשרת על-ידי מערכת אינטראקטיבית מותאמת. אין אנו מסיירים בתערוכה, אלא משנים כל הזמן את מה שמופיע מולנו על המסך, בפעולה המבקשת ליצור אשליית שיטוט. שישה אמנים התבקשו לחשוב על הצגת עבודתם במרחב זה, על שילוב הפיזי והדיגיטלי, על כריכת הישן בחדש. גם באובייקטים עצמם, גם בקשר שלהם עם מרחב התצוגה - תערוכה זו נולדה בדיאלוג בין האמניות והאמנים עם יצירתם, כאוביקט מעבר בין היצירה לגילומה מחדש כייצוג דיגיטלי על מסך. שימוש באמצעים טכנולוגיים ממוחשבים בעת היצירה הוא דבר נפוץ ובמקרים רבים העבודה מוצאת את עצמה לבסוף בחלל פיזי, מוגבלת ברוחב ובגובה, נצמדת לקואורדינטות קפואות. חומר דיגיטלי מתעלם ממאפיינים פיזיים וממגבלות הגשמי. ששת האמנים המשתתפים בתערוכה מציגים עבודות חדשות, והן כולן מותאמות אל המרחב הוירטואלי. במקרים מסוימים המרחב הוירטואלי הוא כור מחצבתן של אותן עבודות, וכעת הן חוזרות לסביבת חייהן המקורית, באחרים זו סביבה פוריה, דוגמת אפשרויות הצבה חדשות, שינוי עיצובי או אינטראקציה.</p> -->
          <p>{{ 'ABOUT.Paragraph-5' | translate }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col text-bold mb-2" style="margin-top: 2.2rem" [dir]="direction">
          <!-- <p>ייחודה של תערוכה זו בכך שהיא מתקיימת בחלל וירטואלי שנוצר במיוחד עבורה. החלל הוא סריקה תלת-ממדית של מבנה בית האמנים בירושלים, החיה בסביבה ממוחשבת מתאימה. העבודות המוצגות בה עשויות מחומר דיגיטלי והתנועה ביניהן מתאפשרת על-ידי מערכת אינטראקטיבית מותאמת. אין אנו מסיירים בתערוכה, אלא משנים כל הזמן את מה שמופיע מולנו על המסך, בפעולה המבקשת ליצור אשליית שיטוט. שישה אמנים התבקשו לחשוב על הצגת עבודתם במרחב זה, על שילוב הפיזי והדיגיטלי, על כריכת הישן בחדש. גם באובייקטים עצמם, גם בקשר שלהם עם מרחב התצוגה - תערוכה זו נולדה בדיאלוג בין האמניות והאמנים עם יצירתם, כאוביקט מעבר בין היצירה לגילומה מחדש כייצוג דיגיטלי על מסך. שימוש באמצעים טכנולוגיים ממוחשבים בעת היצירה הוא דבר נפוץ ובמקרים רבים העבודה מוצאת את עצמה לבסוף בחלל פיזי, מוגבלת ברוחב ובגובה, נצמדת לקואורדינטות קפואות. חומר דיגיטלי מתעלם ממאפיינים פיזיים וממגבלות הגשמי. ששת האמנים המשתתפים בתערוכה מציגים עבודות חדשות, והן כולן מותאמות אל המרחב הוירטואלי. במקרים מסוימים המרחב הוירטואלי הוא כור מחצבתן של אותן עבודות, וכעת הן חוזרות לסביבת חייהן המקורית, באחרים זו סביבה פוריה, דוגמת אפשרויות הצבה חדשות, שינוי עיצובי או אינטראקציה.</p> -->
          <p>{{ 'ABOUT.Paragraph-6' | translate }}</p>
        </div>
      </div>
    </div>
      
    <div class="col-md-special" [ngClass]="{'special-eng': direction === 'ltr'}">
      <div class="welcome-footer">
        <div class="col col-flex mt-4" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="h4 fw-bold">{{ 'ABOUT.Headline' | translate }}</div>
        </div>     
        <div class="col col-flex mt-4" [ngClass]="{'txtltr': direction === 'ltr'}">
        <div class="fw-bold">{{ 'ABOUT.Artists' | translate }}</div>
        <div>{{ 'ABOUT.Artists-list' | translate }}</div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Curator-title' | translate }}</div>
          <div>{{ 'ABOUT.Curator-name' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Artdirector-title' | translate }}</div>
          <div>{{ 'ABOUT.Artdirector-name' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Developer-title' | translate }}</div>
          <div>{{ 'ABOUT.Developer-name' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Edit-title' | translate }}</div>
          <p>{{ 'ABOUT.Edit-name' | translate }}</p>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex mb-4" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Initiative' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Admin-title' | translate }}</div>
          <div>{{ 'ABOUT.Admin-name' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Production-title' | translate }}</div>
          <div>{{ 'ABOUT.Production-name' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex mb-4" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Management-title' | translate }}</div>
          <p>{{ 'ABOUT.Management-name' | translate }}</p>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <div class="fw-bold">{{ 'ABOUT.Support-title' | translate }}</div>
        </div>
      </div>
      <div class="row col-center">
        <div class="col mt-2 col-flex" [ngClass]="{'txtltr': direction === 'ltr'}">
          <img class="new-logo" src="assets/images/logo_jah.png" alt="">
        </div>
      </div>
  </div>

  <div class="row col-center">
    <div class="col mt-2 col-flex mb-4" [ngClass]="{'txtltr': direction === 'ltr'}">
      <h4 class="copyright">{{ 'Copyright' | translate }}</h4>
      <h4 class="copyright2">{{ 'Copyright2' | translate }}</h4>
    </div>
</div>

</div>
</div>
