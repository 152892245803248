import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartPageService {

  private _loadingProgressSource = new Subject<string>();
  loadingProgress$ = this._loadingProgressSource.asObservable();

  private _loadingCompleteSource = new Subject<boolean>();
  loadingComplete$ = this._loadingCompleteSource.asObservable();

  private _readyToStartSource = new Subject<boolean>();
  readyToStart$ = this._readyToStartSource.asObservable();

  constructor() { }

  sendLoadingProgress(progress) {
    this._loadingProgressSource.next(progress);
  }

  sendLoadingComplete() {
    this._loadingCompleteSource.next(true);
  }

  sendReadyToStart() {
    this._readyToStartSource.next(true);
  }

}
