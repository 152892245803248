import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EngineComponent } from './engine/engine.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { ArtistPageComponent } from './pages/artist-page/artist-page.component';
import { MenuPageComponent } from './pages/menu-page/menu-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';


const routes: Routes = [
  // {path: '', component: EngineComponent},
  {path: 'menu', component: MenuPageComponent},
  {path: 'artist/:id', component: ArtistPageComponent},
  {path: 'about', component: AboutPageComponent},
  {path: 'welcome', component: WelcomePageComponent},
  {path: 'help', component: HelpPageComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
