import { Component, OnInit } from '@angular/core';
// import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})

export class AboutPageComponent implements OnInit {

  lang;
  direction;
  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.direction = this.changeDirDirection(this.lang);
   }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
      this.direction = this.changeDirDirection(this.lang);
    });
  }

  changeDirDirection(lang: string): string {
    if (lang === 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

}
