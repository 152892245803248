<!-- <div *ngIf="currentRoute !== '/'" class="lang-toggler-container">
  <div>
    <h1>
      <span (click)="changeLang('en')">E</span>
      /
      <span (click)="changeLang('he')">ע</span>
    </h1>
  </div>
</div> -->

<div *ngIf="currentRoute !== '/' || showToggler" @fadeIn class="translate-middle-x lng-btn-container text-center" [ngStyle]="border">
  <div class="row justify-content-around mt-2 div-lang-chars">
    <div (click)="changeLang()" class="col lang-chars">
      <h5 [ngClass]="lang === 'en' ? 'lng chosen-lang' : 'lng'">E</h5>
      <h5 class="lng">/</h5>
      <h5 [ngClass]="lang === 'he' ? 'lng chosen-lang' : 'lng'">ע</h5>
    </div>
  </div>
</div>
