import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CurtainPageService {

  private _cameraRoamingStateSource = new Subject<boolean>();
  cameraRoamingState$ = this._cameraRoamingStateSource.asObservable();

  constructor() { }

  sendRoamingState(state: boolean) {
    this._cameraRoamingStateSource.next(state);
  }
}
