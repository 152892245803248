import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'artistshouse';

  constructor( private translate: TranslateService) {
    translate.addLangs(['he', 'en']);
    translate.setDefaultLang('he');
    // translate.use('he');
  }
}
