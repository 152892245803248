<div class = "popup" style=" z-index: 1; opacity: 0.52; background-color: black; position: absolute; width: 100%; height: 100%; top: 0;" routerLink=""></div>

<div @fadeIn class="container-fluid position-absolute top-50 start-50 translate-middle page-container">
  <div class="row sticky-top d-sm-none">
    <div class="col-xs header_content"></div>
  </div>
  <div class="row justify-content-center h-adj">
    <div class="col-sm-6 order-sm-2 welcome-page" direction="ltr">
      <div>
        <div class="row welcome-title">
          <div class="col text-center" [ngClass]="{'txtltr': direction === 'ltr'}">
            <div class="exhibit-title" [ngClass]="{'titleltr': direction === 'ltr'}">{{ 'Welcome.Title' | translate }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="fw-bold">{{ 'Welcome.Subtitle' | translate }}</div>
          </div>
        </div>
        <div class="row artists">
          <div class="col text-center">
            <p>{{ 'Welcome.Artists-list' | translate }}</p>
          </div>
        </div>
        <div class="row creators">
          <div class="col order-2 row-flex" [dir]="direction">
            <div class="row col-center">
              <div class="col mt-2">
                <div class="fw-bold" [ngClass]="{'creators-ltr': direction === 'ltr'}">{{ 'Welcome.Curator-title' | translate }}</div>
              </div>
              <div class="col">
                <div class="name-tag">{{ 'Welcome.Curator-name' | translate }}</div>
              </div>
            </div>
            <div class="row col-center">
              <div class="col mt-2">
                <div class="fw-bold" [ngClass]="{'creators-ltr': direction === 'ltr'}">{{ 'Welcome.Artdirector-title' | translate }}</div>
              </div>
              <div class="col">
                <div class="name-tag">{{ 'Welcome.Artdirector-name' | translate }}</div>
              </div>
            </div>
            <div class="row col-center">
              <div class="col mt-2">
                <div class="fw-bold" [ngClass]="{'creators-ltr': direction === 'ltr'}">{{ 'Welcome.Developer-title' | translate }}</div>
              </div>
              <div class="col">
                <div class="name-tag">{{ 'Welcome.Developer-name' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="div-bottom" [ngClass]="{'div-bottom-ltr': direction === 'ltr'}">
      <div class="row row-cols-2 mt-3 m-adj info-buttons">
        <div class="col info-box">
          <div class="row row-margin-bottom">
            <div class="col col-icon">
              <div class="row justify-content-center icon">
                <img class="web-screen" src="assets/SVG/icon-mouse.svg" alt="">
                <img class="mobile-screen" src="assets/SVG/icon-touch.svg" alt="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="info-text text-center mobile-screen">
                {{ 'Welcome.Touch-icon-text' | translate }}
              </div>
              <div class="info-text text-center web-screen">
                {{ 'Welcome.mouse-icon-text' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col info-box info-box-">
          <div class="row row-margin-bottom">
            <div class="col">
              <div class="row justify-content-center">
                <div class="icon-circle-container">
                  <div class="row h-100 justify-content-center align-items-center">
                    <div class="icon-square-container">
                      <div class="row h-100 justify-content-center align-items-center">
                        <figure class="m-0 p-0">
                          <img src="assets/SVG/plus-icon.svg" alt="">
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="info-text text-center">
                {{ 'Welcome.Info-icon-text' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col info-box">
          <div class="row row-margin-bottom">
            <div class="col col-icon">
              <div class="row justify-content-center icon">
                  <img src="assets/SVG/icon-arrows.svg" alt="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="info-text text-center">
                {{ 'Welcome.Control-icon-text' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col info-box">
          <div class="row row-margin-bottom">
            <div class="col">
              <div class="row justify-content-center">
                <div class="icon-circle-container">
                  <div class="row h-100 justify-content-center align-items-center">
                    <div class="icon-square-container">
                      <div class="row h-100 justify-content-center align-items-center">
                        <figure class="m-0 p-1">
                          <img src="assets/SVG/menu.svg" alt="">
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="info-text text-center">
                {{ 'Welcome.Menu-icon-text' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="row justify-content-center continue-button">
        <div class="col-8 col-sm-6 btn-center">
          <div class="btn btn-dark p-3" [ngClass]="{'btnltr': direction === 'ltr'}" [dir]="direction" routerLink="/">{{ 'Welcome.Continue.Button' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

