import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {

  private _virtualControllerSource = new Subject<string>();
  virtualController$ = this._virtualControllerSource.asObservable();

  private _controlForwardSource = new Subject<boolean>();
  controlForward$ = this._controlForwardSource.asObservable();

  private _controlBackwardSource = new Subject<boolean>();
  controlBackward$ = this._controlBackwardSource.asObservable();

  private _controlLeftSource = new Subject<boolean>();
  controlLeft$ = this._controlLeftSource.asObservable();

  private _controlRightSource = new Subject<boolean>();
  controlRight$ = this._controlRightSource.asObservable();

  constructor() { }

  sendDirection(direction: string) {
    this._virtualControllerSource.next(direction);
  }

  sendControlsMoveForward(move: boolean) {
    this._controlForwardSource.next(move);
  }

  sendControlsMoveBackward(move: boolean) {
    this._controlBackwardSource.next(move);
  }

  sendControlsMoveLeft(move: boolean) {
    this._controlLeftSource.next(move);
  }

  sendControlsMoveRight(move: boolean) {
    this._controlRightSource.next(move);
  }
}

