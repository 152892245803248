import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StartPageService } from './start-page.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import gsap from "gsap";


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0,
        display: 'none'
      })),
      transition('show => hide', animate('1000ms ease-out'))
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({opacity: 0}), animate('1s ease-in', style({ opacity: 1}))])
    ]),
    trigger('fadeInAnimationText', [
      transition(':enter', [style({opacity: 0, 'padding-right': '2rem'}), animate('3s ease-in', style({ opacity: 1, 'padding-right': '2rem'}))])
    ]),
    trigger('fadeInAnimationImg', [
      transition(':enter', [style({opacity: 0, 'padding-left': '2rem'}), animate('3s ease-in', style({ opacity: 1, 'padding-left': '2rem'}))])
    ])
  ]
})
export class StartPageComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/SVG/fly-test.json',
  };

  @ViewChild('loadingBar', {static: true})
  public loadingBarRef: ElementRef;

  loadingComplete = false;
  start = false;
  state = 'show';
  startButton = false;
  lang;
  direction;

  constructor(private startPageService: StartPageService, private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.direction = this.changeDirDirection(this.lang);
  }

  ngOnInit(): void {
    this.startPageService.loadingProgress$.subscribe( progress => {
      this.loadingBarRef.nativeElement.style.transform = `scaleX(${progress})`;
    });
    this.startPageService.loadingComplete$.subscribe( (completed) => {
      console.log(completed);
      this.loadingComplete = completed;
      this.loadingBarRef.nativeElement.style.transform = '';
      setTimeout(() => {
        // this.state = 'hide';
        this.startButton = true;
        // console.log(this.startPageRef.nativeElement.style);
        this.start = true;
      }, 1000);
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
      this.direction = this.changeDirDirection(this.lang);
    });
  }

  changeDirDirection(lang: string): string {
    if (lang === 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  startTheShow() {
    this.state = 'hide';
    this.startPageService.sendReadyToStart();
  }


}
