<div class="container-fluid page-container">

  <div class="row justify-content-center">
    <div class="col align-self-center header_content"></div>
  </div>
  
    <div class="div-text" [dir]="direction">
        <div [ngClass]="{'txtltr': direction === 'ltr'}">
            {{ 'Help.sentence1' | translate }}
       
  
    </div>

  </div>

    <div class="div-bottom">
        <div class="row row-cols-2 mt-3 m-adj info-buttons">
            <div class="col info-box">
              <div class="row row-margin-bottom">
                <div class="col col-icon">
                  <div class="row justify-content-center icon">
                    <img class="web-screen" src="assets/SVG/icon-mouse.svg" alt="">
                    <img class="mobile-screen" src="assets/SVG/icon-touch.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="info-text text-center mobile-screen">
                    {{ 'Welcome.Touch-icon-text' | translate }}
                  </div>
                  <div class="info-text text-center web-screen">
                    {{ 'Welcome.mouse-icon-text' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col info-box info-box-">
              <div class="row row-margin-bottom">
                <div class="col">
                  <div class="row justify-content-center">
                    <div class="icon-circle-container">
                      <div class="row h-100 justify-content-center align-items-center">
                        <div class="icon-square-container">
                          <div class="row h-100 justify-content-center align-items-center">
                            <figure class="m-0 p-0">
                              <img src="assets/SVG/plus-icon.svg" alt="">
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="info-text text-center">
                    {{ 'Welcome.Info-icon-text' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col info-box">
              <div class="row row-margin-bottom">
                <div class="col col-icon">
                  <div class="row justify-content-center icon">
                      <img src="assets/SVG/icon-arrows.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="info-text text-center">
                    {{ 'Welcome.Control-icon-text' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col info-box">
              <div class="row row-margin-bottom">
                <div class="col">
                  <div class="row justify-content-center">
                    <div class="icon-circle-container">
                      <div class="row h-100 justify-content-center align-items-center">
                        <div class="icon-square-container">
                          <div class="row h-100 justify-content-center align-items-center">
                            <figure class="m-0 p-0" style="width: 80%;">  
                              <img src="assets/SVG/menu.svg" alt="">
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="info-text text-center">
                    {{ 'Welcome.Menu-icon-text' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>