import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {

  lang;
  direction;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.direction = this.changeDirDirection(this.lang);
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
      this.direction = this.changeDirDirection(this.lang);
    });
  }

  changeDirDirection(lang: string): string {
    if (lang === 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

}
