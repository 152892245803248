<div [@fadeOut]="state" class="container-fluid page-container">
  <div class="row">
    <div class="col">
      <div class="header_content"></div>
    </div>
  </div>
 
  <div class="row div-top">
    <div @fadeInAnimationText class="row justify-content-center text-content" [ngClass]="{'txtltr': direction === 'ltr'}"><div class="col" [dir]="direction">{{ 'START.Content' | translate }}</div></div>
    <div @fadeIn @fadeInAnimationImg class="col text-center mx-auto icon-container">
      <img [src]="!start ? 'assets/SVG/ah-white.svg' : 'assets/SVG/ah-yellow.svg'" alt="ah-icon">
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-6 text-center">
      <div #loadingBar class="loading-bar" [class.ended]="loadingComplete"></div>
    </div>
  </div>
  <div class="row mt-2">
    <div @fadeIn *ngIf="!startButton" class="col text-center">
      <h4 class="wait-message">{{ 'START.Waitmessage' | translate }}</h4>
    </div>
    <div @fadeIn *ngIf="startButton" class="col text-center">
      <div class="btn start-btn" [ngClass]="{'btnltr': direction === 'ltr'}" (click)="startTheShow()" routerLink="/welcome">{{ 'START.Startbutton' | translate }}</div>
    </div>

    <div class="row mt-2 row">
      <div class="col text-center">
        <h4 class="copyright">{{ 'Copyright' | translate }}</h4>
        <h4 class="copyright2">{{ 'Copyright2' | translate }}</h4>
      </div>

  </div>
</div>
<ng-lottie width="3%" height="3%" [options]="options" class="bird"></ng-lottie>
