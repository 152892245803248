
<div class = "popup" style=" z-index: 1; opacity: 0.32; background-color: black; position: absolute; width: 100%; height: 100%; top: 0;" routerLink=""></div>


<div *ngIf="artist" class="container-fluid position-absolute top-50 start-50 translate-middle artist-page-container">
  <div class="row">
  <div class="col header-content"></div>
  </div>
  <div class="row justify-content-center align-items-center mt-3 carousel-safari">
    <div class="col-2 carousel-arrow" (click)="nextArtist(artist.id)">
      <img src="assets/SVG/left-arrow.svg" alt="">
    </div>
    <div class="col-8 text-center fw-bold text-header">
      <h1>{{ 'ARTIST.' + artist.name  | translate }}</h1>
    </div>
    <div class="col-2 carousel-arrow" (click)="previousArtist(artist.id)">
      <img src="assets/SVG/right-arrow.svg" alt="">
    </div>
  </div>
  <div class="row justify-content-center artist-page-down" [ngClass]="{'txtltr': direction === 'ltr'}">
    <div class="col-md-6">
      <div class="d-flex flex-column">
        <div id="scrollBar" class="row justify-content-center row-artist-text">
          <div class="col">
            <p [dir]="direction" class="artist-text" [ngClass]="{'txtltr': direction === 'ltr'}">{{ 'ARTIST.' + artist.text | translate }}</p>
            <p [dir]="direction" class="artist-text" [ngClass]="{'txtltr': direction === 'ltr'}">{{ 'ARTIST.' + artist.text2 | translate }}</p>
            <p [dir]="direction" class="artist-text bolder" [ngClass]="{'txtltr': direction === 'ltr'}">{{ 'ARTIST.' + artist.text3 | translate }}</p>
            <p class="fw-bold" [dir]="direction" class="artist-text" [ngClass]="{'txtltr': direction === 'ltr'}">{{ 'ARTIST.' + artist.bio | translate }}</p>        
          </div>
        </div>
        <div class="row mt-auto align-items-end justify-content-center mb-3">
          <div class="col align-self-end text-center">
            <button class="btn btn-dark" [ngClass]="{'btn-ltr': direction === 'ltr'}" (click)="gotoArtSpot(artist)" routerLink="/">{{ 'ARTIST.ToTheDisplay' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row pb-2">
        <div class="col">
          <div class="row">
            <div class="col image-content"  [ngClass]="{'image-content-ltr': direction === 'ltr'}">
              <img [src]="artist.image" alt="">
            </div>
          </div>
          <div class="row">
            <div class="col text-right" [ngClass]="{'credit-ltr': direction === 'ltr'}">
              <span class="art-text" [dir]="direction">{{ 'ARTIST.' + artist.title | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col text-right" [ngClass]="{'credit-ltr': direction === 'ltr'}">
              <span class="art-text" [dir]="direction">{{ 'ARTIST.' + artist.subtitle | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col text-right" [ngClass]="{'credit-ltr': direction === 'ltr'}">
              <!-- add description to artist -->
              <span class="art-text art-description" [dir]="direction">{{ 'ARTIST.' + artist.subtitle2 | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>

