import { Component, OnInit } from '@angular/core';
import { ArtistsService } from 'src/app/services/artists.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss']
})
export class MenuPageComponent implements OnInit {

  displayArtistList = false;

  artistsList;

  lang;

  constructor(private artistsService: ArtistsService, private translateService: TranslateConfigService) { }

  ngOnInit(): void {
    // this.artistsList = this.artistsService.getArtistsList().sort((a, b) => {
    //   let nameA = a.lastName.toLowerCase(), nameB = b.lastName.toLowerCase();
    //   if (nameA < nameB) return -1;
    //   if (nameA > nameB) return 1;
    //   return 0;
    // });
    this.lang = this.translateService.getCurrentLang();
    console.log(this.lang);
    this.artistsList = this.artistsService.getArtistsList().sort((a, b) => {
      if (this.lang === 'he') {
        return a.heLastName.localeCompare(b.heLastName);
      } else {
        return a.lastName.localeCompare(b.lastName);
      }
    });
    console.log(this.artistsList)
  }

  showArtistList() {
    this.displayArtistList = !this.displayArtistList
  }

}
