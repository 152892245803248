import {
	MathUtils,
	Spherical,
	Vector3
} from 'three';


const _lookDirection = new Vector3();
const _spherical = new Spherical();
const _target = new Vector3();

class FirstPersonControlsCustom {

	constructor( object, domElement, raycaster, navMesh ) {

		if ( domElement === undefined ) {

			console.warn( 'THREE.FirstPersonControlsCustom: The second parameter "domElement" is now mandatory.' );
			domElement = document;

		}

		this.object = object;
		this.domElement = domElement;
		this.ray = raycaster;
		this.navMesh = navMesh;

    	this.intersectObjectName;

		// API

		this.enabled = true;

		this.movementSpeed = 1.0;
		this.lookSpeed = 0.005;

		this.lookVertical = true;
		this.autoForward = false;

		this.activeLook = true;

		this.heightSpeed = false;
		this.heightCoef = 1.0;
		this.heightMin = 0.0;
		this.heightMax = 1.0;

		this.constrainVertical = false;
		this.verticalMin = 0;
		this.verticalMax = Math.PI;

    //
		this.mouseDragOn = false;
		this.onPointerDownMouseX = 0;
		this.onPointerDownMouseY = 0;
		this.long = 0;
		this.lati = 0;
		this.onPointerDownLon = 0;
		this.onPointerDownLat = 0;
		this.phiA = 0;
		this.thetaA = 0;

		// internals

		this.autoSpeedFactor = 0.0;

		this.mouseX = 0;
		this.mouseY = 0;
    	this.deltaX = 0;

		this.mouseDownStart = {
			x: 0,
			y: 0
		}
		this.radius = 500;
		this.mouseDownLon = 0;
		this.mouseDownLat = 0;

		this.moveForward = false;
		this.moveBackward = false;
		this.moveLeft = false;
		this.moveRight = false;

		this.viewHalfX = 0;
		this.viewHalfY = 0;

		// private variables

		let lat = 0;
		let lon = 0;

    	const axisY = new Vector3(0, 1, 0);	

		//

		this.handleResize = function () {

			if ( this.domElement === document ) {

				this.viewHalfX = window.innerWidth / 2;
				this.viewHalfY = window.innerHeight / 2;

			} else {

				this.viewHalfX = this.domElement.offsetWidth / 2;
				this.viewHalfY = this.domElement.offsetHeight / 2;

			}

		};

		this.onMouseDown = function ( event ) {

			if ( this.domElement !== document ) {

				this.domElement.focus();

			}

			event.preventDefault();

			if ( this.activeLook ) {

				switch ( event.button ) {

					case 0: this.moveForward = true; break;
					case 2: this.moveBackward = true; break;

				}

			}

			// mouse coordinates
			// console.log(event.clientX);


			this.mouseDragOn = true;

			this.onPointerDownMouseX = event.clientX;
			this.onPointerDownMouseY = event.clientY;

			this.onPointerDownLon = this.long;
			this.onPointerDownLat = this.lati;
			// this.mouseDownStart.x = event.clientX;
			// this.mouseDownStart.y = event.clientY;

      		// this.mouseDownStart.x = event.clientX / window.innerWidth;
			// this.mouseDownStart.y = - ( event.clientY / window.innerHeight);
			// console.log(this.mouseDownStart);
			// console.log(this.object.quaternion);
		};

		this.onMouseUp = function ( event ) {

			event.preventDefault();

			if ( this.activeLook ) {

				switch ( event.button ) {

					case 0: this.moveForward = false; break;
					case 2: this.moveBackward = false; break;

				}

			}

			this.mouseDragOn = false;
			// this.moveRight = false;
			// this.moveLeft = false;
			console.log(this.object.quaternion);

		};

		this.onMouseMove = function ( event ) {

			// if ( this.domElement === document ) {

			// 	this.mouseX = event.pageX - this.viewHalfX;
			// 	this.mouseY = event.pageY - this.viewHalfY;


			// } else {

			// 	this.mouseX = event.pageX - this.domElement.offsetLeft - this.viewHalfX;
			// 	this.mouseY = event.pageY - this.domElement.offsetTop - this.viewHalfY;

			// }




	  //when mouse is down
      if (this.mouseDragOn) {

        this.long = ( this.onPointerDownMouseX - event.clientX ) * 0.1 + this.onPointerDownLon;
        this.lati = ( event.clientY - this.onPointerDownMouseY ) * 0.1 + this.onPointerDownLat;

		// move to target
        // console.log(this.object);

        // lon = (this.mouseDownStart.x - event.clientX) * 0.1;
        // lat = (event.clientY - this.mouseDownStart.y) * 0.1;

        // lat = Math.max(-20, Math.min(20, lat));

        // const phi = MathUtils.degToRad(90 - lat);
        // const theta = MathUtils.degToRad(lon);

        // let targetX = this.radius * Math.sin(phi) * Math.cos(theta);
        // let targetY = this.radius * Math.cos(phi);
        // let targetZ = this.radius * Math.sin(phi) * Math.sin(theta);

        // let target = new Vector3(targetX, targetY, targetZ)

        // this.radius = Math.hypot(_target.x, _target.y, _target.z);
        // console.log(this.radius);

        // this.lookAt(target);
        // console.log(`phi: ${phi}`);
        // console.log(`theta: ${theta}`);
        // console.log(`lon: ${lon}`);
        // console.log(`lat: ${lat}`);
        // console.log(this.object);



      }

      // console.log(this.mouseX);
      // console.log(this.mouseY);

		};

    this.castRayForward = function ( ) {
      const forwardRayOrgin = new Vector3(this.object.position.x, this.object.position.y, this.object.position.z + ((this.movementSpeed * 0.1) * 2)) //0.1 is update delta!!
      // const backwardRayOrgin = new Vector3(this.object.position.x, this.object.position.y, this.object.position.z - (this.movementSpeed * 0.1)) //0.1 is update delta!!

      let forwardCubePosition = new Vector3();
      this.object.children[0].getWorldPosition(forwardCubePosition);
      this.ray.set(forwardCubePosition, new Vector3(0, -1, 0));
      const intersect = this.ray.intersectObject(this.navMesh, true);
      console.log(intersect);



      if (intersect.length > 0) {

        this.intersectObjectName = intersect[0].object.name;
        console.log(this.intersectObjectName);

        if (intersect[0].distance < 0.5826 + 0.5) {
          const deltaHeight = (0.5826 + 0.5) - intersect[0].distance;
          this.object.position.y += deltaHeight;
          this.object.updateProjectionMatrix();
        }
        if (intersect[0].distance > 0.5826 + 0.5) {

          const deltaHeight = intersect[0].distance - ( 0.5826 + 0.5 );
          this.object.position.y -= deltaHeight;
          this.object.updateProjectionMatrix();
        }
        console.log(this.object.position)
		console.log(this.navMesh);
        return true;
      }
      return false;
    }

    // this.castRayforward2 = function ( ) {
    //   let cwd = new Vector3();
    //   let dist = (this.movementSpeed * 0.1) * 2;

    //   this.object.getWorldDirection(cwd);
    //   console.log(cwd);
    //   cwd.multiplyScalar(dist);
    //   this.ray.set(cwd, new Vector3(0, 1, 0));
    //   const intersect = this.ray.intersectObject(this.navMesh);

    //   console.log(cwd);

    //   if (intersect.length > 0) {
    //     console.log(intersect[0]);
    //     return true;
    //   }
    //   return false;
    // }


    this.castRayBackward = function ( ) {
      // const backwardRayOrgin = new Vector3(this.object.position.x, this.object.position.y, this.object.position.z - ((this.movementSpeed * 0.1) * 2)) //0.1 is update delta!!
      let backwardCubePosition  = new Vector3();
      this.object.children[1].getWorldPosition(backwardCubePosition);


      this.ray.set(backwardCubePosition, new Vector3(0, -1, 0));
      const intersect = this.ray.intersectObject(this.navMesh, true);

      // console.log(backwardRayOrgin);
      // console.log(this.movementSpeed);
      // console.log(this.object.rotation);


      if (intersect.length > 0) {
        console.log(intersect[0])
        this.intersectObjectName = intersect[0].object.name;
        console.log(this.intersectObjectName);

        if (intersect[0].distance < 0.5826 + 0.5) {
          const deltaHeight = (0.5826 + 0.5) - intersect[0].distance;
          this.object.position.y += deltaHeight;
          this.object.updateProjectionMatrix();
        }
        if (intersect[0].distance > 0.5826 + 0.5) {
          const deltaHeight = intersect[0].distance - ( 0.5826 + 0.5 );
          this.object.position.y -= deltaHeight;
          this.object.updateProjectionMatrix();
        }
        return true;
      }
      return false;
    }


		this.onKeyDown = function ( event ) {

			//event.preventDefault();

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW':

          this.moveForward = true; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = true; break;

				case 'ArrowDown':
				case 'KeyS':

          this.moveBackward = true; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = true; break;

				//case 'KeyR': this.moveUp = true; break;
				//case 'KeyF': this.moveDown = true; break;

			}

		};

		this.onKeyUp = function ( event ) {

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW': this.moveForward = false; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = false; break;

				case 'ArrowDown':
				case 'KeyS': this.moveBackward = false; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = false; break;

				case 'KeyR': this.moveUp = false; break;
				case 'KeyF': this.moveDown = false; break;

			}

		};

		this.lookAt = function ( x, y, z ) {

			if ( x.isVector3 ) {

				_target.copy( x );

			} else {

				_target.set( x, y, z );

			}

			this.object.lookAt( _target );

			setOrientation( this );

			return this;

		};

		this.update = function () {

			const targetPosition = new Vector3();

			return function update( delta ) {

				if ( this.enabled === false ) return;

				if ( this.heightSpeed ) {

					const y = MathUtils.clamp( this.object.position.y, this.heightMin, this.heightMax );
					const heightDelta = y - this.heightMin;

					this.autoSpeedFactor = delta * ( heightDelta * this.heightCoef );

				} else {

					this.autoSpeedFactor = 0.0;

				}

				const actualMoveSpeed = delta * this.movementSpeed;

				if ( (this.moveForward || ( this.autoForward && ! this.moveBackward )) && this.castRayForward() ) this.object.translateZ( - ( actualMoveSpeed + this.autoSpeedFactor ) );
				if ( this.moveBackward && this.castRayBackward()) this.object.translateZ( actualMoveSpeed );

				if ( this.moveLeft ) {
          this.object.rotateOnWorldAxis( axisY, actualMoveSpeed);
					// this.object.rotateY( actualMoveSpeed )
					// setOrientation(this);
					this.object.updateProjectionMatrix();

				};
				if ( this.moveRight ) {
					this.object.rotateOnWorldAxis( axisY,  - actualMoveSpeed )
					// setOrientation(this);
					this.object.updateProjectionMatrix();
          // setOrientation( this );
				};

				if ( this.moveUp ) this.object.translateY( actualMoveSpeed );
				if ( this.moveDown ) this.object.translateY( - actualMoveSpeed );

				let actualLookSpeed = delta * this.lookSpeed;

				if ( ! this.activeLook ) {

					actualLookSpeed = 0;

				}

				let verticalLookRatio = 1;

				if ( this.constrainVertical ) {

					verticalLookRatio = Math.PI / ( this.verticalMax - this.verticalMin );

				}

        if (this.mouseDragOn) {
          this.lati = Math.max( -40, Math.min( 40, this.lati ));
          this.phiA = MathUtils.degToRad( 90 - this.lati );
          this.thetaA = MathUtils.degToRad( this.long );

          const targetX = this.radius * Math.sin( this.phiA ) * Math.cos( this.thetaA );
          const targetY = this.radius * Math.cos( this.phiA );
          const targetZ = this.radius * Math.sin( this.phiA ) * Math.sin( this.thetaA );
          console.log( targetX, targetY, targetZ );

          this.lookAt( targetX, targetY, targetZ );
          this.radius = Math.hypot(targetX, targetY, targetZ);
          console.log(`radius: ${this.radius}`);
        }



				// lon -= this.mouseX * actualLookSpeed;
				// if ( this.lookVertical ) lat -= this.mouseY * actualLookSpeed * verticalLookRatio;

				// lat = Math.max( - 85, Math.min( 85, lat ) );

				// let phi = MathUtils.degToRad( 90 - lat );
				// const theta = MathUtils.degToRad( lon );

				// if ( this.constrainVertical ) {

				// 	phi = MathUtils.mapLinear( phi, 0, Math.PI, this.verticalMin, this.verticalMax );

				// }
				// const position = this.object.position;

				// targetPosition.setFromSphericalCoords( 1, phi, theta ).add( position );

				// this.object.lookAt( targetPosition );
			};

		}();

		this.dispose = function () {

			this.domElement.removeEventListener( 'contextmenu', contextmenu );
			this.domElement.removeEventListener( 'pointerdown', _onMouseDown );
			this.domElement.removeEventListener( 'pointermove', _onMouseMove );
			this.domElement.removeEventListener( 'pointerup', _onMouseUp );

			window.removeEventListener( 'keydown', _onKeyDown );
			window.removeEventListener( 'keyup', _onKeyUp );

		};

		const _onMouseMove = this.onMouseMove.bind( this );
		const _onMouseDown = this.onMouseDown.bind( this );
		const _onMouseUp = this.onMouseUp.bind( this );
		const _onKeyDown = this.onKeyDown.bind( this );
		const _onKeyUp = this.onKeyUp.bind( this );

		this.domElement.addEventListener( 'contextmenu', contextmenu );
		this.domElement.addEventListener( 'pointermove', _onMouseMove );
		this.domElement.addEventListener( 'pointerdown', _onMouseDown );
		this.domElement.addEventListener( 'pointerup', _onMouseUp );

		window.addEventListener( 'keydown', _onKeyDown );
		window.addEventListener( 'keyup', _onKeyUp );

		function setOrientation( controls ) {

			const quaternion = controls.object.quaternion;

			_lookDirection.set( 0, 0, - 1 ).applyQuaternion( quaternion );
			_spherical.setFromVector3( _lookDirection );

			lat = 90 - MathUtils.radToDeg( _spherical.phi );
			lon = MathUtils.radToDeg( _spherical.theta );

		}

		this.handleResize();

		setOrientation( this );

	}

}

function contextmenu( event ) {

	event.preventDefault();

}

export { FirstPersonControlsCustom };
