import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(private translateService: TranslateService) {
    translateService.use('he');
  }

  changeLang(type: string) {
    this.translateService.use(type);
  }

  getDefaultLang() {
    return this.translateService.getDefaultLang();
  }

  getCurrentLang() {
    return this.translateService.currentLang;
  }

  onLangChange() {
    this.translateService.onLangChange;
  }
}
