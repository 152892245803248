import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EngineComponent } from './engine/engine.component';
import { VirtualControllerComponent } from './ui/virtual-controller/virtual-controller.component';
import { LogoComponent } from './ui/logo/logo.component';
import { MenuButtonComponent } from './ui/menu-button/menu-button.component';
import { MenuPageComponent } from './pages/menu-page/menu-page.component';
import { ArtistPageComponent } from './pages/artist-page/artist-page.component';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { LangTogglerComponent } from './ui/lang-toggler/lang-toggler.component';
import { VrButtonComponent } from './ui/vr-button/vr-button.component';
import { HelpButtonComponent } from './ui/help-button/help-button.component';
import { InfoButtonComponent } from './ui/info-button/info-button.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { CurtainPageComponent } from './pages/curtain-page/curtain-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    EngineComponent,
    VirtualControllerComponent,
    LogoComponent,
    MenuButtonComponent,
    MenuPageComponent,
    ArtistPageComponent,
    StartPageComponent,
    LangTogglerComponent,
    VrButtonComponent,
    HelpButtonComponent,
    InfoButtonComponent,
    AboutPageComponent,
    CurtainPageComponent,
    WelcomePageComponent,
    HelpPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
