import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-vr-button',
  templateUrl: './vr-button.component.html',
  styleUrls: ['./vr-button.component.scss']
})
export class VrButtonComponent implements OnInit {

  currentRoute: string;

  constructor(private router: Router) {
    router.events.subscribe( event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        console.log(this.currentRoute);
      }
    });
  }

  ngOnInit(): void {
  }

}
