import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StartPageService } from 'src/app/pages/start-page/start-page.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-lang-toggler',
  templateUrl: './lang-toggler.component.html',
  styleUrls: ['./lang-toggler.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({opacity: 0}), animate('1s ease-in', style({ opacity: 1}))])
    ])
  ]
})
export class LangTogglerComponent implements OnInit, AfterViewInit {

  currentRoute: string;
  lang: string;
  border = {'border': 'initial'};
  showToggler = true;

  mobileMedia:any = window.matchMedia('(max-width: 576px)');



  constructor(private router: Router, private translateService: TranslateConfigService, private startPageService: StartPageService) {
    router.events.subscribe( event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        // console.log(`this is lng toggler: ${this.currentRoute}`);
        if (this.currentRoute === '/menu' && this.mobileMedia.matches) {
          this.border = {'border':'3px solid black'}
        } else if (this.currentRoute === '/menu' && !this.mobileMedia.matches) {
          this.border = {'border':'3px solid black'}
        }
        if (this.currentRoute === '/welcome' || this.currentRoute.startsWith('/artist')) {
          this.border = {'border':'3px solid black'}
        }
        if (this.currentRoute === '/start') {
          this.border = {'border':'0px solid black'}
        }
      }
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getCurrentLang();
    // console.log(this.lang);
    // if (this.currentRoute === '/menu') {
    //   this.border = '3px solid black'
    // }
    this.startPageService.readyToStart$.subscribe((ready) => {
      this.showToggler = ready;
    });
  }

  ngAfterViewInit() {
    // this.lang = this.translateService.getCurrentLang();
    // console.log(this.lang);
  }


  changeLang() {
    let changeLngTo = 'he';
    if (this.lang === 'en') {
      changeLngTo = 'he';
    } else if (this.lang === 'he') {
      changeLngTo = 'en';
    }
    this.translateService.changeLang(changeLngTo);
    this.lang = changeLngTo;
  }

}
