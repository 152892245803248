<div class="menu-button-container">
  <div *ngIf="currentRoute === '/menu' || currentRoute.startsWith('/artist') || currentRoute.startsWith('/about') || currentRoute.startsWith('/welcome') || currentRoute.startsWith('/help'); else menuBlock" class="close-button" routerLink="">
    <img src="/assets/SVG/x.svg" alt="menu">
  </div>
  <ng-template #menuBlock>
    <div class="menu-button" routerLink="/menu">
      <img src="/assets/SVG/menu.svg" alt="menu">
    </div>
  </ng-template>
</div>
