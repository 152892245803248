import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CurtainPageService } from './curtain-page.service';


@Component({
  selector: 'app-curtain-page',
  templateUrl: './curtain-page.component.html',
  styleUrls: ['./curtain-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({opacity: 0}), animate('1s ease-in', style({ opacity: 1}))]),
      transition(':leave', [animate('1s ease-in', style({ opacity: 0}))])
    ])
  ]
})
export class CurtainPageComponent implements OnInit {

  state: boolean = false;

  constructor(private curtainPageService: CurtainPageService) { }

  ngOnInit(): void {
    this.curtainPageService.cameraRoamingState$.subscribe(state => {
      this.state = state;
    });
  }

}
