import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ArtistsService {

  artistsList = [
    {
      id: '1',
      name: 'Carmi',
      lastName: 'Dror',
      heLastName: 'c',
      translateName: 'MENU.Artist_Carmi',
      title: 'Carmi-Title',
      subtitle: 'Carmi-Subtitle',
      subtitle2: 'Carmi-Subtitle2',
      text: 'Carmi-Text',
      text2: 'Carmi-Text2',
      text3: 'Carmi-Text3',
      bio: 'Carmi-Bio',
      image: 'assets/images/carmi-work.jpg',
      galleryLocation: {
        camPos: {x: 3.016, y: 0.47, z: -3.781}, 
        lookat: {x: 481.568, y: 16.566, z: -133.481}
      }
    },
    {
      id: '2',
      name: 'Tal',
      lastName: 'Boniel',
      heLastName: 'b',
      translateName: 'MENU.Artist_Tal',
      title: 'Tal-Title',
      subtitle: 'Tal-Subtitle',
      subtitle2: 'Tal-Subtitle2',
      text: 'Tal-Text',
      text2: 'Tal-Text2',
      text3: 'Tal-Text3',
      bio: 'Tal-Bio',
      image: 'assets/images/tal-work.jpg',
      galleryLocation: {
        camPos: {x: 5.715, y: 5.633, z: -0.118},
        lookat: {x: 1.881, y: 5.633, z: -0.117}
      }
    },
    {
      id: '3',
      name: 'Nivi',
      lastName: 'Alroy',
      heLastName: 'a',
      translateName: 'MENU.Artist_Nivi',
      title: 'Nivi-Title',
      subtitle: 'Nivi-Subtitle',
      subtitle2: 'Nivi-Subtitle2',
      text: 'Nivi-Text',
      text2: 'Nivi-Text2',
      text3: 'Nivi-Text3',
      bio: 'Nivi-Bio',
      image: 'assets/images/nivi-work.jpg',
      galleryLocation: {
        camPos: {x: 1.399, y: 5.576, z: 3.139},
        lookat: {x: 446, y: 5.576, z: 224.2}
      }
    },
    {
      id: '4',
      name: 'Dor',
      lastName: 'Zlekha Levy',
      heLastName: 'd',
      translateName: 'MENU.Artist_Dor',
      title: 'Dor-Title',
      subtitle: 'Dor-Subtitle',
      subtitle2: 'Dor-Subtitle2',
      text: 'Dor-Text',
      text2: 'Dor-Text2',
      text3: 'Dor-Text3',
      bio: 'Dor-Bio',
      image: 'assets/images/dor-work.jpg',
      galleryLocation: {
        camPos: {x: 13.93, y: 2.776, z: -3.625},
        lookat: {x: 14.68, y: 2.76, z: 0.8}
      }
    },

    {
      id: '5',
      name: 'Keren',
      lastName: 'Russo',
      heLastName: 'f',
      translateName: 'MENU.Artist_Keren',
      title: 'Keren-Title',
      subtitle: 'Keren-Subtitle',
      subtitle2: 'Keren-Subtitle2',
      text: 'Keren-Text',
      text2: 'Keren-Text2',
      text3: 'Keren-Text3',
      bio: 'Keren-Bio',
      image: 'assets/images/karen-work.jpg',
      galleryLocation: {
        camPos: {x: 13.437, y: 5.676, z: -0.223},
        lookat: {x: 499.992, y: 5.676, z: 9.84}
      }
    },
   
    {
      id: '6',
      name: 'Miri',
      lastName: 'Segal',
      heLastName: 'e',
      translateName: 'MENU.Artist_Miri',
      title: 'Miri-Title',
      subtitle: 'Miri-Subtitle',
      subtitle2: 'Miri-Subtitle2',
      text: 'Miri-Text',
      text2: 'Miri-Text2',
      text3: 'Miri-Text3',
      bio: 'Miri-Bio',
      image: 'assets/images/miri-work.jpg',
      galleryLocation: {
        camPos: {x: 3.472, y: 5.676, z: -2.022},
        lookat: {x: 12.692, y: 22.669, z: -499.324}
      }
    }

  ]

  constructor() { }

  getArtistsList() {
    return this.artistsList;
  }

  getArtistFromArrayById(id) {
    const artist = this.artistsList.find( artist => artist.id === id);
    return artist;
  }


  getNextArtist(currentArtistId) {
    const currentArtist = this.getArtistFromArrayById(currentArtistId);
    const currentArtistIndex = this.artistsList.indexOf(currentArtist);
    let nextArtist;
    if (currentArtistIndex >= 0 && currentArtistIndex < this.artistsList.length -1) {
      nextArtist = this.artistsList[currentArtistIndex + 1];
      return nextArtist;
    } else if (currentArtistIndex === this.artistsList.length - 1){
      nextArtist = this.artistsList[0];
      return nextArtist;
    }
  }

  getPreviousArtist(currentArtistId) {
    const currentArtist = this.getArtistFromArrayById(currentArtistId);
    const currentArtistIndex = this.artistsList.indexOf(currentArtist);
    let previousArtist;
    if (currentArtistIndex <= this.artistsList.length -1 && currentArtistIndex > 0) {
      previousArtist = this.artistsList[currentArtistIndex - 1];
      return previousArtist;
    } else if (currentArtistIndex === 0) {
      previousArtist = this.artistsList[this.artistsList.length -1];
      return previousArtist;
    }
  }
}
