import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {

  currentRoute: string;

  constructor(private router: Router) {
    router.events.subscribe( event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit(): void {
    // this.router.events.subscribe( event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log(event);
    //     this.currentRoute = event.url;
    //     console.log(this.currentRoute);
    //   }
    // })
  }

}
