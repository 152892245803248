<div>
  <div class="engine-wrapper">
    <canvas #rendererCanvas id="renderCanvas"></canvas>
  </div>
  <video #videoR id="video" loop="true" [muted]="'muted'" playsinline style="display:none">
    <source src="assets/Tet_Stadt_16_9_MASTER_Prores_2.mp4" type="video/mp4">
  </video>
  <video #videoFire id="videoFire" loop="true" [muted]="'muted'" playsinline style="display:none">
    <source src="assets/fire-orb.mp4" type="video/mp4">
  </video>
  <video #miriAudio id="miriAudio" playsinline width="100%" height="200" style="-webkit-transform:translate3d(0,0,0);">
    <source src="assets/audio/miri-room-soundtrack/miri-soundtrack.mp3" type="audio/mp3">
    <track label="English" kind="subtitles" srclang="en" src="assets/audio/miri-room-soundtrack/miri-soundtrack-subtitiles.vtt" default>
  </video>
  <!-- <audio #dorAudio id="dorAudio">
    <source src="assets/dor-sound.mp3" type="audio/mp3">
  </audio> -->
  <app-logo></app-logo>
  <app-menu-button></app-menu-button>
  <app-virtual-controller></app-virtual-controller>
  <app-help-button></app-help-button>
  <app-info-button></app-info-button>
  <!-- <app-vr-button></app-vr-button> -->
</div>

