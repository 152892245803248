<app-logo></app-logo>
<app-menu-button></app-menu-button>
<!-- <app-lang-toggler></app-lang-toggler> -->

<div class="container-fluid page-container fw-bold">
  <div class="row justify-content-center">
    <div class="col align-self-center header_content"></div>
  </div>
  <div class="row justify-content-center mb-3 pb-1 div-header">
    <div class="col text-center title_content">
      <!-- <h1>אוביקט מעבר</h1> -->
      <h1 class="title_content">{{ 'MENU.Title' | translate }}</h1>
      <!-- <h6>תערוכה קבוצתית מקוונת</h6> -->
      <h6 class="subtitle_content">{{ 'MENU.Subtitle' | translate }}</h6>
    </div>
  </div>
  <div class="row justify-content-center fix-row div-center">
    <div class="col-sm align-self-center text-item">
        <div class="text-center" dir="rtl" routerLink="/about">
          <!-- <h2>אודות התערוכה</h2> -->
          <h2>{{ 'MENU.About' | translate }}</h2>
        </div>
    </div>
    <div class="col-sm-1 align-self-center vertical-line-container divider">
      <div class="vertical-line"></div>
    </div>
    <div class="col-sm align-self-center artists-list-container text-item">
      <div class="text-center" dir="rtl">
        <h2 *ngIf="!displayArtistList" (click)="showArtistList()">{{ 'MENU.Artists' | translate }}</h2>
        <div class="hide-artists" [ngClass]="{'show-artists':displayArtistList}">
          <div *ngFor="let artist of artistsList" class="artist" routerLink="/artist/{{artist.id}}">{{ artist.translateName | translate}}</div>
          <!-- <div class="artist" routerLink="/artist/1">{{ 'MENU.Artist_Carmi' | translate}}</div>
          <div class="artist" routerLink="/artist/2">{{ 'MENU.Artist_Tal' | translate}}</div>
          <div class="artist" routerLink="/artist/3">{{ 'MENU.Artist_Nivi' | translate}}</div>
          <div class="artist" routerLink="/artist/4">{{ 'MENU.Artist_Dor' | translate}}</div>
          <div class="artist" routerLink="/artist/5">{{ 'MENU.Artist_Keren' | translate}}</div>
          <div class="artist" routerLink="/artist/6">{{ 'MENU.Artist_Miri' | translate}}</div> -->
        </div>
      </div>
    </div>
    <div class="col-sm-1 align-self-center divider">
      <div class="vertical-line-right"></div>
    </div>
    <div class="col-sm align-self-center text-item">
      <div class="text-center" dir="rtl" routerLink="/help">
        <!-- <h2>כיצד לשוטט?</h2> -->
        <h2>{{ 'MENU.Howto' | translate}}</h2>
      </div>
    </div>
  </div>
  <div class="row fixed-row-bottom">
    <div class="col">
      <!-- <h2 class="text-center"> E/ע</h2> -->
    </div>
    <!-- <app-lang-toggler></app-lang-toggler> -->
  </div>
</div>
