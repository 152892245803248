import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ArtistPageService } from '../pages/artist-page/artist-page.service';
import { StartPageService } from '../pages/start-page/start-page.service';
import { ControllerService } from '../ui/virtual-controller/controller.service';
import {EngineService} from './engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html'
})
export class EngineComponent implements OnInit {

  @ViewChild('rendererCanvas', {static: true})
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('videoR', {static: true})
  public video: ElementRef<HTMLVideoElement>;

  @ViewChild('videoFire', {static: true})
  public videoFire: ElementRef<HTMLVideoElement>;

  @ViewChild('miriAudio', {static: true})
  public miriAudio: ElementRef<HTMLAudioElement>;

  public constructor(
    private engServ: EngineService,
    private controllerService: ControllerService,
    private artistPageService: ArtistPageService,
    private startPageService: StartPageService) {
  }

  public ngOnInit(): void {
    this.startPageService.readyToStart$.subscribe((ready) => {
      if (ready) {
        this.engServ.playVideo();
      }
    });
    this.artistPageService.cameraPosition$.subscribe( spot => {
      console.log("this spot")
      console.log(spot);
      this.engServ.setCameraPosition(spot);
    });
    this.controllerService.virtualController$.subscribe((direction) => {
      if (direction === 'right') {
        this.engServ.turnCameraRight();
      }
      if (direction === 'left') {
        this.engServ.turnCameraLeft();
      }
      if (direction === 'forward') {
        this.engServ.moveForward();
      }
      if (direction === 'backward') {
        this.engServ.moveBackward();
      }
      if (direction === 'stop') {
        this.engServ.stopMovement();
      }
    });
    this.engServ.createScene(this.rendererCanvas, this.video, this.videoFire, this.miriAudio);
    this.engServ.animate();
  }

}


