import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ArtistsService } from 'src/app/services/artists.service';
import { ArtistPageService } from './artist-page.service';

@Component({
  selector: 'app-artist-page',
  templateUrl: './artist-page.component.html',
  styleUrls: ['./artist-page.component.scss']
})
export class ArtistPageComponent implements OnInit {
  private routeSub;

  artistsList = [];

  artist;
  lang;
  direction;

  constructor(
    private artistsService: ArtistsService, 
    private route: ActivatedRoute, 
    private artistPageService: ArtistPageService,
    private translateService: TranslateService) {
      this.lang = this.translateService.currentLang;
      this.direction = this.changeDirDirection(this.lang);
    }

  ngOnInit(): void {
    this.artistsList = this.artistsService.getArtistsList();
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params);
      this.artist = this.artistsService.getArtistFromArrayById(params['id']);
      // console.log(this.artist);
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang
      this.direction = this.changeDirDirection(this.lang);
    });
  }

  changeDirDirection(lang: string): string {
    if (lang === 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  gotoArtSpot(artist) {
    console.log(artist);
    let spot = {
      name: artist.name,
      camPos: artist.galleryLocation.camPos,
      lookat: artist.galleryLocation.lookat
    };
    this.artistPageService.sendCameraPosition(spot);


    console.log(this.artist.galleryLocation);
  }

  nextArtist(currentId) {
    this.artist = this.artistsService.getNextArtist(currentId);
  }

  previousArtist(currentId) {
    this.artist = this.artistsService.getPreviousArtist(currentId);
  }



}
