<div *ngIf="currentRoute === '/'" class="translate-middle-x info-button-container" (click)="openArtistPageRoute()">
  <div [@blink]="blinking" (@blink.done)="blinkingDone()" class="info-button">
    <img src="/assets/SVG/plus-icon.svg" alt="">
  </div>
</div>


<!-- <div *ngIf="currentRoute === '/'" class="info-button-container text-center" (click)="openArtistPageRoute()">
  <div class="row justify-content-around mt-2">
    <div [@blink]="blinking" (@blink.done)="blinkingDone()" class="col">
      <img src="/assets/SVG/plus-icon.svg" alt="">
    </div>
  </div>
</div> -->
