<!-- <div class="controller">
  <div class="ctrl-row">
    <div class="ctrl-up"
      (pointerdown)="moveForward()"
      (pointerup)="stopMovement()"
      (mousedown)="moveForward()"
      (mouseup)="stopMovement()"
    >
      <img src="assets/SVG/control-arrow-up.svg" alt="">
    </div>
  </div>
  <div class="ctrl-row">
    <div class="ctrl-left"
      (pointerdown)="turnLeft()"
      (pointerup)="stopMovement()"
      (mousedown)="turnLeft()"
      (mouseup)="stopMovement()"
    >
      <img src="assets/SVG/control-arrow-left.svg" alt="">
    </div>
    <div class="ctrl-down"
      (pointerdown)="moveBackward()"
      (pointerup)="stopMovement()"
      (mousedown)="moveBackward()"
      (mouseup)="stopMovement()"
    >
      <img src="assets/SVG/control-arrow-down.svg" alt="">
    </div>
    <div class="ctrl-right"
      (pointerdown)="turnRight()"
      (pointerup)="stopMovement()"
      (mousedown)="turnRight()"
      (mouseup)="stopMovement()"
    >
    <img src="assets/SVG/control-arrow-right.svg" alt="">
    </div>
  </div> -->
  <!-- <div class="ctrl-row">
    <div class="ctrl-down"
      (pointerdown)="moveBackward()"
      (pointerup)="stopMovement()"
      (mousedown)="moveBackward()"
      (mouseup)="stopMovement()"
    >
      <img src="/assets/SVG/uparrow.svg" alt="">
    </div>
  </div> -->
<!-- </div> -->
<div class="controller-container position-absolute bottom-0 start-50 translate-middle-x">
  <div [@press]="forward" class="row justify-content-center arrow-container">
    <div class="arrow-center"
      (pointerdown)="moveForward()"
      (pointerup)="stopMovement()"
      (mousedown)="moveForward()"
      (mouseup)="stopMovement()">
      <img class="img-fluid" img draggable="false" oncontextmenu="return false;" src="assets/SVG/control-arrow-up.svg" alt="">
    </div>
  </div>
  <div class="row justify-content-center pt-2">
    <div [@press]="left" class="arrow-container arrow-center"
      (pointerdown)="turnLeft()"
      (pointerup)="stopMovement()"
      (mousedown)="turnLeft()"
      (mouseup)="stopMovement()">
      <img class="img-fluid" img draggable="false" oncontextmenu="return false;" src="assets/SVG/control-arrow-up.svg" alt="" style="transform: rotate(-90deg);">
    </div>
    <div [@press]="backward" class="arrow-container arrow-center"
      (pointerdown)="moveBackward()"
      (pointerup)="stopMovement()"
      (mousedown)="moveBackward()"
      (mouseup)="stopMovement()">
      <img class="img-fluid" img draggable="false" oncontextmenu="return false;" src="assets/SVG/control-arrow-up.svg" alt="" style="transform: rotate(180deg);">
    </div>
    <div [@press]="right" class="arrow-container arrow-center"
      (pointerdown)="turnRight()"
      (pointerup)="stopMovement()"
      (mousedown)="turnRight()"
      (mouseup)="stopMovement()">
      <img class="img-fluid" img draggable="false" oncontextmenu="return false;"  src="assets/SVG/control-arrow-up.svg" alt="" style="transform: rotate(90deg);">
    </div>
  </div>
</div>



