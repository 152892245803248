import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EngineService } from 'src/app/engine/engine.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';




@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  animations: [
    trigger('blink', [
      state('true', style({ backgroundColor: '#FAD368'})),
      state('false', style({ backgroundColor:'transparent'})),
      transition('* <=> *', animate('3s', keyframes([
        style({ backgroundColor: 'transparent'}),
        style({ backgroundColor: '#FAD368'}),
        style({ backgroundColor: 'transparent'}),
        style({ backgroundColor: '#FAD368'}),
        style({ backgroundColor: 'transparent'}),
        style({ backgroundColor: '#FAD368'}),
      ])))
    ])
  ]
  
})
export class InfoButtonComponent implements OnInit {
  
  mobileMedia:any = window.matchMedia('(max-width: 576px)');
  order = {'z-index': '23'};

  currentRoute: string;
  artistPageRoute: string;
  blinking: boolean = false;

  constructor(private router: Router, private engineService: EngineService) {
    router.events.subscribe( event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        // console.log(this.currentRoute);
      }
      if (this.mobileMedia.matches) {
       this.order = {'z-index':'24'}
      }
    });
  }


  ngOnInit(): void {

    this.engineService.navmeshName$.subscribe((name) => {
      // console.log(`info button subscription ${name}`);
      if (name !== 'SampleScene_Exported_NavMesh001') {
        this.blinking = true;
      }
    });
  }

  openArtistPageRoute() {
    // console.log('click')
    const navmeshName = this.engineService.getNavMeshName();
    this.artistPageRoute = this.navmeshNameToArtistRoute(navmeshName);
    this.router.navigateByUrl(this.artistPageRoute);
  }

  blinkingDone() {
    this.blinking = false;
  }


  navmeshNameToArtistRoute(navmeshName) {
    if (navmeshName === 'navKaren') {
      return 'artist/5'
    } else if (navmeshName === 'navDor') {
      return 'artist/4'
    } else if (navmeshName === 'navNivi') {
      return 'artist/3'
    } else if (navmeshName === 'navMiri') {
      return 'artist/6'
    } else if (navmeshName === 'navTal') {
      return 'artist/2'
    } else if (navmeshName === 'navCarmi') {
      return 'artist/1'
    } else if (navmeshName === 'SampleScene_Exported_NavMesh001') {
      return '/about'
    } else {
      return '/about'
    }
  }

}
