import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArtistPageService {

  private _cameraPositionSource = new Subject<{name: String, camPos: {x: number, y: number, z: number}, lookat: {x: number, y: number, z: number}}>();
  cameraPosition$ = this._cameraPositionSource.asObservable();

  constructor() { }

  sendCameraPosition(spot: {name: String, camPos: {x: number, y: number, z: number}, lookat: {x: number, y: number, z: number}}) {
    this._cameraPositionSource.next(spot);
  }
}
