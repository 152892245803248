import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ControllerService } from './controller.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-virtual-controller',
  templateUrl: './virtual-controller.component.html',
  styleUrls: ['./virtual-controller.component.scss'],
  animations: [
    trigger('press', [
      state('active', style({ backgroundColor: '#FAD368', transform: 'scale(0.98)', boxShadow: '0px 0px 0px 0px #000018'})),
      transition('* => active', animate(100))
    ])
  ]
})
export class VirtualControllerComponent implements OnInit {
  forward: string;
  backward: string;
  left: string;
  right: string;

  constructor(private controllerService: ControllerService, public cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.controllerService.controlForward$.subscribe((move) => {
      console.log(move);
      move ? this.forward = 'active' : this.forward = 'notActive';
      console.log(this.forward);
      this.cd.detectChanges();
    });
    this.controllerService.controlBackward$.subscribe((move) => {
      move ? this.backward = 'active': this.backward = 'notActive';
      this.cd.detectChanges();
    });
    this.controllerService.controlLeft$.subscribe((move) => {
      move ? this.left = 'active': this.left = 'notActive';
      this.cd.detectChanges();
    });
    this.controllerService.controlRight$.subscribe((move) => {
      move ? this.right = 'active': this.right = 'notActive';
      this.cd.detectChanges();
    });
  }

  turnRight() {
    this.controllerService.sendDirection('right');
  }

  turnLeft() {
    this.controllerService.sendDirection('left');
  }

  moveForward() {
    this.controllerService.sendDirection('forward');
  }

  moveBackward() {
    this.controllerService.sendDirection('backward');
  }

  stopMovement() {
    this.controllerService.sendDirection('stop');
  }

}
