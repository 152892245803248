import * as THREE from 'three';
import {ElementRef, Injectable, NgZone, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";


import { FirstPersonControlsCustom } from './FirstPersonControls';
// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper'
// import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper.js';

import gsap from "gsap";

import { StartPageService } from '../pages/start-page/start-page.service';
import { CurtainPageService } from '../pages/curtain-page/curtain-page.service';
import { Subject } from 'rxjs';
import { ControllerService } from '../ui/virtual-controller/controller.service';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';

import { Reflector } from 'three/examples/jsm/objects/Reflector.js';


import Stats from 'stats.js';
// import * as dat from 'dat.gui';



@Injectable({providedIn: 'root'})
export class EngineService implements OnDestroy {
  private currentRoute: string;
  private canvas: HTMLCanvasElement;
  private video: HTMLVideoElement;
  private videoFire: HTMLVideoElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private light: THREE.AmbientLight;
  private hemisphereLight: THREE.HemisphereLight;
  private rectangleLight: THREE.RectAreaLight;
  private spotLight: THREE.SpotLight;
  private controls: any;
  private raycaster: THREE.Raycaster;
  private loadingManager: THREE.LoadingManager;
  private dracoLoader: DRACOLoader;
  private gltfLoader: GLTFLoader;
  private plyLoader: PLYLoader;
  private basisLoader: BasisTextureLoader;
  private ktx2Loader: KTX2Loader;

  private audioLoader: THREE.AudioLoader;
  private audioListener: THREE.AudioListener;
  private positionalAudio: THREE.PositionalAudio;

  private dorSound: THREE.Audio;
  private inDorRoom: boolean;

  private inKarenRoom: boolean;


  private galleryModel;
  private animationsList;
  private pointsMesh: THREE.Points;


  //boat animation
  private boatAnimationMixer: THREE.AnimationMixer;
  private boat: THREE.Mesh;
  private boatAction: THREE.AnimationAction;
  private boatPlane: THREE.AnimationClip;

  private boatAnimationMixer2: THREE.AnimationMixer;
  private boat2: THREE.Mesh;
  private boatAction2: THREE.AnimationAction;

  //miri room
  private inMiriRoom: boolean = false;
  private inMiriRoomTime: number;
  private bluePill: THREE.Mesh;
  private redPill: THREE.Mesh;
  private miriPicOnFloor: THREE.Mesh;
  private miriTextOnDoor: THREE.Mesh;
  private scaledUp: boolean;
  private miriDoorAnimationMixer: THREE.AnimationMixer;
  private miriDoor: THREE.Mesh;
  private miriDoorIsClosed: boolean;
  private miriLoadedMirror: THREE.Mesh;
  private miriMirror: Reflector;
  private miriMirrorFrame: THREE.Mesh;
  private miriDoorAction: THREE.AnimationClip;
  private isExecuted: boolean = false;
  private miriAudioListener: THREE.AudioListener;
  private miriDoorPositionalAudio: THREE.PositionalAudio;
  private miriAudioFireListener: THREE.AudioListener;
  private miriFirePositionalAudio: THREE.PositionalAudio;
  private miriRoomSoundTrack;
  private miriNavMesh;
  private timedMirror;
  private timedDrop;
  private timedDoor;
  private timedPicOnFloor;
  private timedTextOnDoor;
  private timedPills;
  private timedBlue;
  private timedFire;
  private timedOpen;


  private fireAnimationMixer: THREE.AnimationMixer;
  private fire: THREE.Mesh;
  private fireAction: THREE.AnimationAction;

  // private frameGiometry = THREE.PlaneGeometry;
  // private frameMaterial = THREE.MeshStandardMaterial;
  // private frameMesh = THREE.Mesh;

  //post processing
  // private effectComposer: EffectComposer;
  // private renderPass: RenderPass;



  // stats = new Stats();
  // private gui = new dat.GUI();

  clock = new THREE.Clock();
  prv;

  private navmesh;

  private navmeshName;
  private _navmeshNameSource = new Subject<string>();
  navmeshName$ = this._navmeshNameSource.asObservable();


  //controls subjects
  private controlForward;
  private controlBackward;
  private controlLeft;
  private controlRight;

  parameters = {
    ambientLightColor: 0xffffff,
    ambientLightIntensity: 0.6,
    hemisphereLightSkyColor: 0xffffff,
    hemisphereLightGroundColor: 0xffffff,
    hemisphereLightIntensity: 0.7,
    rectangleLightColor: 0xffffff,
    rectangleLightIntensity: 1,
    spotLightColor: 0xffffff,
    spotLightIntensity: 1
  }

  private cube: THREE.Mesh;
  private cubeBack: THREE.Mesh;

  private frameId: number = null;

  public constructor(
    private ngZone: NgZone, 
    private startPageService: StartPageService, 
    private curtainPageService: CurtainPageService, 
    private controllerService: ControllerService,
    private router: Router) {
      router.events.subscribe( event => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>, video: ElementRef<HTMLVideoElement>, videoFire: ElementRef<HTMLVideoElement>, miriAudio: ElementRef<HTMLAudioElement>): void {

    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;
    this.video = video.nativeElement;
    this.videoFire = videoFire.nativeElement;


    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true, // smooth edges
      powerPreference: 'high-performance'
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.setPixelRatio(window.devicePixelRatio);
    // this.renderer.physicallyCorrectLights = true;
    this.renderer.gammaFactor = 2.2;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.setClearColor (0xff0000, 1); // background color
    // create the scene
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      65, window.innerWidth / window.innerHeight, 0.1, 100
    );

    const boxGeometry = new THREE.BoxGeometry(0.02, 0.02, 0.02);
    const boxMaterial = new THREE.MeshBasicMaterial({color: 0x000000, transparent:true, opacity:0});
    this.cube = new THREE.Mesh(boxGeometry, boxMaterial);
    this.cubeBack = new THREE.Mesh(boxGeometry, boxMaterial);
    // this.scene.add(this.cube, this.cubeBack);

    this.camera.add(this.cube, this.cubeBack);
    // set cube infront of camera
    this.cube.position.set(this.camera.position.x, this.camera.position.y, this.camera.position.z - 0.3);
    // set cube back to camera
    this.cubeBack.position.set(this.camera.position.x, this.camera.position.y, this.camera.position.z + 0.3);

    this.cube.visible = false;
    // this.camera.layers.enable(1);
    this.scene.add(this.camera);


    this.camera.position.y = 0.5;
    this.camera.position.x = 0.6;

    this.camera.rotation.y = -(Math.PI * 0.5);

    // init pathfinder
    // this.pathfinding = new Pathfinding();

    
    this.loadingManager = new THREE.LoadingManager(
      () => {
        setTimeout(() => {
          this.startPageService.sendLoadingComplete();
          // this.video.play();
        }, 1000)

      }, (itemUrl, itemsLoaded, itemsTotal) => {
        console.log('progress');
        const progressRatio = itemsLoaded / itemsTotal;
        console.log(progressRatio);
        this.startPageService.sendLoadingProgress(progressRatio);
      }
    );

    this.loadingManager.onStart = () => {
      console.log('start loading');
    }

    //audion loader
    this.audioLoader = new THREE.AudioLoader();


    this.ktx2Loader = new KTX2Loader();
    this.ktx2Loader.setTranscoderPath('assets/basis/');
    this.ktx2Loader.detectSupport( this.renderer );
    this.ktx2Loader.basisLoader.workerConfig.etc1Supported = false;
    
    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath('assets/draco/');


    this.gltfLoader = new GLTFLoader(this.loadingManager);
    this.gltfLoader.setDRACOLoader(this.dracoLoader);
    this.gltfLoader.setKTX2Loader(this.ktx2Loader);
    this.gltfLoader.load('../../../assets/omanimHouseModelDracoKtx26-4.glb', (gltf) => {
      // console.log(gltf);

      //load way2
      this.galleryModel = gltf.scene;
      // console.log(this.galleryModel);
      this.animationsList = gltf.animations;

      this.galleryModel.traverse((node) => {
        if (node.isMesh && node.name === "SampleScene_Exported_NavMesh001") {
          this.navmesh = node;
        }
        if (node.isMesh && node.name === "navMiri") {
          this.miriNavMesh = node;
        }
        if (node.isMesh && node.name === "boat") {
          this.boat = node;
        }
        if (node.isMesh && node.name === "boat2") {
          this.boat2 = node;
        }
        if (node.isMesh && node.name === "firefire") {
          this.fire = node;
        }
        if (node.isMesh && node.name === "blue-pill") {
          this.bluePill = node;
        }
        if (node.isMesh && node.name === "red-pill") {
          this.redPill = node;
        }
        if (node.isMesh && node.name === "miri-photo") {
          this.miriPicOnFloor = node;
        }
        if (node.isMesh && node.name === "miri-photo2") {
          this.miriTextOnDoor = node;
        }
        if (node.isMesh && node.name === "MiriDoor") {
          this.miriDoor = node;
        }
        if (node.isMesh && node.name === "mirror-frame") {
          this.miriMirrorFrame = node;
        }
        if (node.isMesh && node.name === "mirror") {
          this.miriLoadedMirror = node;
          this.miriLoadedMirror.visible = false;
           this.miriMirror = new Reflector( this.miriLoadedMirror.geometry.clone(), {
           clipBias: 0.003,
           textureWidth: window.innerWidth * window.devicePixelRatio,
					 textureHeight: window.innerHeight * window.devicePixelRatio,
					 color: 0x777777
         })

        }
      });
      

      this.boatAnimationMixer = new THREE.AnimationMixer(this.boat);
      this.boatAction = this.boatAnimationMixer.clipAction(THREE.AnimationClip.findByName(gltf.animations, 'boatAction'));
      this.boatAction.play();

      this.boatAnimationMixer2 = new THREE.AnimationMixer(this.boat2);
      this.boatAction2 = this.boatAnimationMixer2.clipAction(THREE.AnimationClip.findByName(gltf.animations, 'boat2Action'));
      this.boatAction2.play();

      this.setMiriDoorAnimation(this.animationsList);


     this.miriDoorAnimationMixer = new THREE.AnimationMixer(this.miriDoor);
     this.miriDoorAction = this.miriDoorAnimationMixer.clipAction(THREE.AnimationClip.findByName(gltf.animations, 'MiriDoorAction'));
     this.miriDoorAction.setLoop(THREE.LoopOnce);
     this.miriDoorAction.clampWhenFinished = true;

      this.miriAudioListener = new THREE.AudioListener();
      this.miriDoorPositionalAudio = new THREE.PositionalAudio(this.miriAudioListener);
      this.audioLoader.load('assets/audio/miri-1.mp3', buffer => {
        this.miriDoorPositionalAudio.setBuffer(buffer);
        this.miriDoorPositionalAudio.setRefDistance(1);
      });

      this.miriDoor.add(this.miriDoorPositionalAudio);

      this.miriAudioFireListener = new THREE.AudioListener();
      this.miriFirePositionalAudio = new THREE.PositionalAudio(this.miriAudioFireListener);
      this.audioLoader.load('assets/audio/miri-fire.mp3', buffer => {
        this.miriFirePositionalAudio.setBuffer(buffer);
        this.miriFirePositionalAudio.setRefDistance(1);
      });

      this.fire.add(this.miriFirePositionalAudio);

      this.miriMirror.material = new THREE.MeshStandardMaterial({color: 'red'});


      const videoFireTexture = new THREE.VideoTexture(this.videoFire);
      videoFireTexture.flipY = false;
      const fireWindowMaterial = new THREE.MeshBasicMaterial({ map: videoFireTexture })

      this.fire.material = fireWindowMaterial;

      this.bluePill.material.visible = false;
      this.redPill.material.visible = false;
      this.miriPicOnFloor.material.visible = false;
      this.miriTextOnDoor.material.visible = false;
      this.miriMirrorFrame.material.visible = false;
      this.miriMirror.material.visible = false;
      this.fire.material.visible = false;


      // this.navmesh.material.transparent = true;
      // this.navmesh.material.opacity = 0.5;
      this.navmesh.material.visible = false;
      this.scene.add(this.galleryModel);

      this.controls = new FirstPersonControlsCustom(this.camera, this.canvas, this.raycaster, this.navmesh);
      this.controls.activeLook = false;
      this.controls.movementSpeed = 0.2;

    }, (xhr) => {
      console.log( (xhr.loaded / xhr.total * 100) + '% loaded')
      const loadingRatio = xhr.loaded / xhr.total;
      this.startPageService.sendLoadingProgress(loadingRatio);

    }, (error) => {
      console.log(error);
    });


    // Raycaster
    this.raycaster = new THREE.Raycaster();
    this.raycaster.far = 3;

    this.plyLoader = new PLYLoader(this.loadingManager);
    this.plyLoader.load('../../../assets/x.ply', (geometry) => {
      console.log(geometry);


      if (navigator.userAgent.indexOf('Safari') !=-1 && navigator.userAgent.indexOf('Chrome') == -1) {
        const pointMaterial = new THREE.PointsMaterial({ vertexColors: true, size: 0.005});
        this.pointsMesh = new THREE.Points(geometry, pointMaterial);
        this.pointsMesh.scale.set(0.14, 0.14, 0.14);
        this.pointsMesh.rotation.z = Math.PI-1.09;
        this.pointsMesh.rotation.y = -3.1;
        this.pointsMesh.rotation.x = 0.01;
  
        this.pointsMesh.position.set( 6.1, 0.5, -4.2);
  
        this.scene.add(this.pointsMesh);
    } else {
        // Nope, it's another browser =(
    
      const pointMaterial = new THREE.PointsMaterial({ vertexColors: true, size: 0.002});
      this.pointsMesh = new THREE.Points(geometry, pointMaterial);
      this.pointsMesh.scale.set(0.14, 0.14, 0.14);
      this.pointsMesh.rotation.z = Math.PI-1.09;
      this.pointsMesh.rotation.y = -3.1;
      this.pointsMesh.rotation.x = 0.01;

      this.pointsMesh.position.set( 6.1, 0.5, -4.2);

      this.scene.add(this.pointsMesh);
    }

    }, ( xhr ) => {
      console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    }, error => {
      console.log(error);
    });



    //soft white light
    this.light = new THREE.AmbientLight(this.parameters.ambientLightColor, this.parameters.ambientLightIntensity);
    this.light.position.z = 10;
    this.scene.add(this.light);

    //Hemisphere light
    this.hemisphereLight = new THREE.HemisphereLight(this.parameters.hemisphereLightSkyColor, this.parameters.hemisphereLightGroundColor, this.parameters.hemisphereLightIntensity);
    this.scene.add(this.hemisphereLight);



    //Rectangle Area Light;
    // this.rectangleLight = new THREE.RectAreaLight(this.parameters.rectangleLightColor, this.parameters.rectangleLightIntensity, 1.5, 1.8);
    // const helper = new RectAreaLightHelper(this.rectangleLight)
    // this.rectangleLight.add(helper)
    // this.rectangleLight.position.set(1.6, 7.07, -2.11);
    // this.rectangleLight.rotation.y = Math.PI;

    // this.scene.add(this.rectangleLight)
    // this.scene.add(helper);

    //  Add video
    const planeGeometry = new THREE.PlaneGeometry( 4.75, 2.75 );
    const videoTexture = new THREE.VideoTexture(this.video);
    const planeMaterial = new THREE.MeshBasicMaterial({ map: videoTexture })
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.position.x = 17.73;
    plane.position.y = 6.14;
    plane.position.z = -0.15;
    plane.rotation.y = - Math.PI / 2;
    this.scene.add(plane);

    // adding audio

    this.audioListener =  new THREE.AudioListener();
    this.camera.add(this.audioListener);

    this.positionalAudio = new THREE.PositionalAudio(this.audioListener);
    // this.audioLoader = new THREE.AudioLoader();
    this.audioLoader.load('assets/Tet_Stadt_16_9_MASTER_Prores_audio.mp3', buffer => {
      this.positionalAudio.setBuffer(buffer);
      this.positionalAudio.setRefDistance(1);
      this.positionalAudio.loop = true;
			// this.positionalAudio.play();
			this.positionalAudio.setDirectionalCone( 130, 230, 0 );

      // const helper =  new PositionalAudioHelper( this.positionalAudio);
      // this.positionalAudio.add(helper);
    })

    plane.add(this.positionalAudio);

    // Dor soundtrack
    this.dorSound = new THREE.Audio(this.audioListener);

    this.audioLoader.load('assets/dor-sound.mp3', (buffer) => {
      this.dorSound.setBuffer(buffer);
      this.dorSound.setLoop(true);
    });


    if (this.isIOS()) {
      this.miriRoomSoundTrack = new THREE.Audio(this.audioListener);
      this.audioLoader.load('assets/audio/miri-room-soundtrack/miri-soundtrack.mp3', (buffer) => {
        this.miriRoomSoundTrack.setBuffer(buffer);
      });
    } else {
      this.miriRoomSoundTrack = miriAudio.nativeElement;
    }

    // this.miriAudioListener = new THREE.AudioListener();
    // this.miriDoorPositionalAudio = new THREE.PositionalAudio(this.miriAudioListener);
    // this.audioLoader.load('assets/audio/miri-1.mp3', buffer => {
    //   this.miriDoorPositionalAudio.setBuffer(buffer);
    //   this.miriDoorPositionalAudio.setRefDistance(20);
    // });

    // this.miriDoor.add(this.miriDoorPositionalAudio);


     //frame for tal art
    // this.frameGiometry = new THREE.PlaneGeometry(1.5, 1.8);
    // this.frameMaterial = new THREE.MeshStandardMaterial( {color: 0xff0000 });
    // this.frameMesh = new THREE.Mesh(this.frameGiometry, this.frameMaterial);
    // this.frameMesh.position.set(1.6, 7.07, -2.11);
    // this.frameMesh.layers.enable(1);
    // this.scene.add(this.frameMesh);
    // console.log(this.frameMesh.layers);
    // console.log(this.camera.layers);



    // this.effectComposer = new EffectComposer(this.renderer);
    // this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    // this.effectComposer.setSize(window.innerWidth, window.innerHeight);

    // this.renderPass = new RenderPass(this.scene, this.camera);
    // this.effectComposer.addPass(this.renderPass);

    // const unrealBloomPass = new UnrealBloomPass();
    // unrealBloomPass.threshold = 0;
    // unrealBloomPass.strength = 5.5;
    // unrealBloomPass.radius = 1;
    // unrealBloomPass.enabled = true;
    // this.effectComposer.addPass(unrealBloomPass);


    // this.gui.add(this.frameMesh.position, 'x').min(-10).max(10).step(0.01).name('frame p-x');
    // this.gui.add(this.frameMesh.position, 'y').min(-10).max(10).step(0.01).name('frame p-y');
    // this.gui.add(this.frameMesh.position, 'z').min(-10).max(10).step(0.01).name('frame p-z');
    //  this.gui.add(this.frameMesh, 'width').min(1.0).max(4).step(0.01).name('width');
    //  this.gui.add(this.frameMesh, 'height').min(1.0).max(4).step(0.01).name('height');


    // STats
    // this.stats.showPanel(0);
    // document.body.appendChild(this.stats.dom);

  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        this.resize();
      });

    });
  }

  public render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });
    // this.stats.begin();
    const elapsedTime = this.clock.getElapsedTime();
    // const delta = this.clock.getDelta();
    const now = new Date().getTime();
    let dlt = now - this.prv;
    this.prv = now;

    if (this.controls) {

      if (this.controlForward !== this.controls.moveForward) {
        this.controlForward = this.controls.moveForward;
        this.controllerService.sendControlsMoveForward(this.controlForward);
      }

      if (this.controlBackward !== this.controls.moveBackward) {
        this.controlBackward = this.controls.moveBackward;
        this.controllerService.sendControlsMoveBackward(this.controlBackward);
      }

      if (this.controlLeft !== this.controls.moveLeft) {
        this.controlLeft = this.controls.moveLeft;
        this.controllerService.sendControlsMoveLeft(this.controlLeft);
      }

      if (this.controlRight !== this.controls.moveRight) {
        this.controlRight = this.controls.moveRight;
        this.controllerService.sendControlsMoveRight(this.controlRight);
      }

      if ((this.currentRoute === '/welcome' || this.currentRoute === '/menu') && this.controls.enabled) {
        this.disableControls();
      }

      if (this.currentRoute === '/' && !this.controls.enabled) {
        this.enableControls();
      }

      if (this.navmeshName !== this.controls.intersectObjectName) {
        this.navmeshName = this.controls.intersectObjectName
        // console.log(this.navmeshName);
        this.sendNavmeshName(this.navmeshName);
        if (this.navmeshName === 'navMiri' && !this.inMiriRoom) {
          this.inMiriRoom = true;
          this.inMiriRoomTime = elapsedTime;
        }

        if (this.navmeshName === 'navDor' && !this.inDorRoom) {
          // console.log('IN DOR ROOM')
          this.inDorRoom = true;
          this.pauseVideo();
          this.dorSound.play()
        }

        if (this.navmeshName !== 'navDor' && this.inDorRoom) {
          // console.log("OUT of Dor Room");
          this.inDorRoom = false;
          this.dorSound.stop();
          this.playVideo();
        }

        if (this.navmeshName === 'navKaren' && !this.inKarenRoom) {
          this.inKarenRoom = true;
          this.positionalAudio.setVolume(1);
        }

        if (this.navmeshName !== 'navKaren' && this.inKarenRoom) {
          this.inKarenRoom = false;
          this.positionalAudio.setVolume(0);
        }

      }

      this.controls.update(0.005 * dlt);
    }

    if (this.inMiriRoom && (elapsedTime - this.inMiriRoomTime > 1) && !this.isExecuted) {

      this.pauseVideo();

      // Door sound
      this.miriDoorPositionalAudio.play();


      this.timedMirror = setTimeout(() => {


        this.miriRoomSoundTrack.play();
        

      }, 1200);


      this.timedDrop = setTimeout(() => {
        const g = new THREE.CircleGeometry(5.1, 32);
        this.miriMirror = new Reflector(g, {
          clipBias: 0.003,
          textureWidth: window.innerWidth * window.devicePixelRatio,
          textureHeight: window.innerHeight * window.devicePixelRatio,
          color: 0x777777
        })
        this.miriMirror.position.x = 19.6;
        this.miriMirror.position.y = 27.30;
        this.miriMirror.position.z = -34.7;

        this.miriMirror.rotation.x = -0.05; // Skew to wall

        this.galleryModel.add(this.miriMirror);
        this.scene.add(this.miriMirror);
        this.miriMirrorFrame.material.visible = true;
      }, 3100)

      // Door Animation /  Soundtrack  /  Pills
        this.timedDoor = setTimeout(() => {
        this.miriDoorAction.play();
        this.miriDoorIsClosed = true
 
      }, 4000);


      this.timedTextOnDoor = setTimeout(() => {
        this.miriTextOnDoor.material.visible = true;
      }, 28150);

      this.timedPills = setTimeout(() => {
        this.bluePill.material.visible = true;
        this.redPill.material.visible = true;
      }, 51100);

   //   this.timedBlue = setTimeout(() => {
   //     this.bluePill.material.visible = false;
   //   }, 54000);


      this.timedPicOnFloor = setTimeout(() => {
        this.miriPicOnFloor.material.visible = true;
      }, 100000);


      this.timedFire = setTimeout(() => {
        
        this.bluePill.material.visible = false;
        this.miriPicOnFloor.material.visible = false;
        this.fire.position.set(
          this.camera.position.x / 5, 
          (this.camera.position.y / 5) + 0.20, 
          this.camera.position.z / 5
        );
        this.fire.material.visible = true;
        this.miriFirePositionalAudio.play();
      }, 139800);


      this.timedOpen = setTimeout(() => {
        if (this.miriDoorIsClosed) {
          this.miriDoorAction.paused = false;
          this.miriDoorAction.timeScale = -1;
          this.miriDoorAction.setLoop(THREE.LoopOnce);
          this.miriDoorAction.play();
          this.miriDoorIsClosed = false;
        }
        this.galleryModel.traverse((node) => {
          if (node.isMesh && node.name === "SampleScene_Exported_NavMesh001") {
            this.controls.navMesh = node;
          }
        });
      }, 210900);
      // this.bluePill.material.visible = true;
      // this.redPill.material.visible = true;
      // this.fire.material.visible = true;

      // this.galleryModel.scale.set(3, 3, 3);

      // scaling up
      if (!this.scaledUp) {
        gsap.to(
          this.camera.position, 
          {
            duration: 1, 
            x: this.camera.position.x *5.2, 
            y: (this.camera.position.y * 5) - 4.5, 
            z: this.camera.position.z *5.2
          }
        )

        gsap.to(
          this.galleryModel.scale, 
          {
            duration: 1.0, 
            x: 5, 
            y: 5, 
            z: 5, 
            ease: 'Power4.out', 
            onComplete: () => {
              this.scaledUp = true;
              this.controls.navMesh = this.miriNavMesh;
              console.log(this.miriLoadedMirror.position);
            }
          }
        );
      }

      this.isExecuted = true;

    }

    if (this.scaledUp && this.navmeshName !== 'navMiri') {
      // console.log("GOING UP!!!")
      this.curtainPageService.sendRoamingState(true);
      gsap.to(
        this.camera.position, 
        {delay: 1.0, duration: 0.3, x: 3.5176, y: 5.5787, z: -1.9442}
      )

      gsap.to(
        this.galleryModel.scale, 
        {
          delay: 1.0, duration: 0.6, x: 1, y: 1, z: 1, ease: 'Power4.out', 
          onComplete: () => {
            this.scaledUp = false;
            this.curtainPageService.sendRoamingState(false);
          }
        }
      );

      // Reset Room here!
      this.resetMiriRoom();
    }

    if (this.boatAnimationMixer) {
      this.boatAnimationMixer.update(0.0015*dlt);
    }
    if (this.boatAnimationMixer2) {
      this.boatAnimationMixer2.update(0.0015*dlt);
    }
    if (this.miriDoorAnimationMixer) {
      this.miriDoorAnimationMixer.update(0.0015*dlt);
    }


    this.renderer.render(this.scene, this.camera);
    // this.stats.end();
  }

  public resize(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
    this.controls.handleResize();
  }


  public turnCameraRight() {
    this.controls.moveRight = true;
  }

  public turnCameraLeft() {
    this.controls.moveLeft = true;
  }

  public moveForward() {
    this.controls.moveForward = true;
  }

  public moveBackward() {
    this.controls.moveBackward = true;
  }

  public stopMovement() {
    this.controls.moveForward = false;
		this.controls.moveBackward = false;
		this.controls.moveLeft = false;
		this.controls.moveRight = false;
  }

  public setCameraPosition( spot) {
    const camPos = spot.camPos;

    // console.log(this.camera.position);
    const lookat = new THREE.Vector3(spot.lookat.x, spot.lookat.y, spot.lookat.z);

    this.curtainPageService.sendRoamingState(true);

    gsap.to(this.camera.position, {delay: 2, duration: 0.3, x: camPos.x, y: camPos.y, z: camPos.z, onComplete: ()=>{
      this.camera.lookAt(lookat);
      this.camera.updateProjectionMatrix();
      this.curtainPageService.sendRoamingState(false);
    }});
    // Reset room here (if excuted)
    if (this.isExecuted) {
      this.resetMiriRoom()
    }
  }

  getNavMeshName() {
    this.controls.castRayForward();
    return this.controls.intersectObjectName;
  }

 
  /* public addSpotLight(position: any, target: any, color: any, intensity: any, angle: any) {
    const spot = new THREE.SpotLight(color, intensity, 0, angle);
    spot.position.set(position.x, position.y, position.z)
    spot.target.position.set(target.x, target.y, target.z);
    this.scene.add(spot, spot.target);
  }
  */

  playVideo() {
    this.video.play();
    this.positionalAudio.play();
    this.videoFire.play();
    // console.log(this.positionalAudio.getVolume())
    this.positionalAudio.setVolume(0);
    // console.log(this.positionalAudio.getVolume())
  }

  pauseVideo() {
    this.video.pause();
    this.positionalAudio.pause()
  }

  sendNavmeshName(name: string) {
    this._navmeshNameSource.next(name);
  }

  setMiriDoorAnimation(gltfAnimations) {
    this.miriDoorAnimationMixer = new THREE.AnimationMixer(this.miriDoor);
    this.miriDoorAction = this.miriDoorAnimationMixer.clipAction(THREE.AnimationClip.findByName(gltfAnimations, 'MiriDoorAction'));
    this.miriDoorAction.setLoop(THREE.LoopOnce);
    this.miriDoorAction.clampWhenFinished = true;
  }

  resetMiriRoom() {
    this.isExecuted = false;
    this.inMiriRoom = false;
    this.miriDoorAction.stop();
    if (this.miriDoorIsClosed) {
      // console.log('DOOR CLOSAED OPENING NOW')
      this.miriDoorAction.paused = false;
      this.miriDoorAction.timeScale = -1;
      this.miriDoorAction.setLoop(THREE.LoopOnce);
      this.miriDoorAction.play();
      this.miriDoorIsClosed = false;
    }
    this.setMiriDoorAnimation(this.animationsList);
    // this.miriDoorAction.stop();
    // this.miriDoorAnimationMixer.uncacheClip(this.miriDoorAction);
    this.bluePill.material.visible = false;
    this.redPill.material.visible = false;
    this.miriPicOnFloor.material.visible = false;
    this.miriTextOnDoor.material.visible = false;
    this.miriMirrorFrame.material.visible = false;
    this.miriMirror.material.visible = false;
    this.fire.material.visible = false;
    if (this.scaledUp) {
      // this.curtainPageService.sendRoamingState(true);
      gsap.to(
        this.galleryModel.scale, 
        {
          delay: 1, duration: 0.3, x: 1, y: 1, z: 1, ease: 'Power4.out', 
          onComplete: () => {
            // console.log("NOW COMPLETED")
            this.scaledUp = false;
            // this.curtainPageService.sendRoamingState(false);
          }
        }
      );
    }

    clearTimeout(this.timedMirror);
    clearTimeout(this.timedDrop);
    clearTimeout(this.timedDoor);
    clearTimeout(this.timedPicOnFloor);
    clearTimeout(this.timedPills);
    clearTimeout(this.timedBlue);
    clearTimeout(this.timedTextOnDoor);
    clearTimeout(this.timedFire);
    clearTimeout(this.timedOpen);

    this.miriDoorPositionalAudio.stop();
    this.miriRoomSoundTrack.pause();
    this.miriRoomSoundTrack.currentTime = 0;

    this.playVideo();

    this.galleryModel.traverse((node) => {
      if (node.isMesh && node.name === "SampleScene_Exported_NavMesh001") {
        this.controls.navMesh = node;
      }
    });


  }


  disableControls() {
    return this.controls.enabled = false;
  }

  enableControls() {
    return this.controls.enabled = true;
  }

   isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

}


